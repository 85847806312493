import axios from "axios";
import {
  createContext,
  useEffect,
  useState,
  ReactNode,
  useContext,
} from "react";
import { toast } from "react-toastify";

interface UserContextProps {
  isLogin: boolean;
  loggedinUser: any;
  path: string;
  mockAPI: boolean;
  noAcc: boolean;
  setLoggedinUser: (e: any) => void;
  setNoAcc: (e: boolean) => void;
  setMockAPI: (e: boolean) => void;
  setPath: (e: string) => void;
  fetchUser: () => void;
  saveSettingsToAuth: (objToSave: any, key: string) => Promise<any>;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

interface UserProviderProps {
  children: ReactNode;
}

// CONTEXT USED IN ALL THE COMPONENTS TO AVOID PROPS DRILLING

export const UserAuthProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [noAcc, setNoAcc] = useState<boolean>(false);
  const [mockAPI, setMockAPI] = useState<boolean>(false);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [loggedinUser, setLoggedinUser] = useState<any>();
  const [path, setPath] = useState<string>("");

  useEffect(() => {
    if (typeof window !== "undefined" && window.location) {
      const pathname = window.location.pathname;
      const searchPathRisk = pathname.includes("risk-monitor");
      const searchPathAnalytics = pathname.includes("analytics");
      setPath(
        searchPathRisk ? "risk" : searchPathAnalytics ? "analytics" : "trading"
      );
      fetchUser();
    }
  }, []);

  const fetchUser = async () => {
    try {
      const user: any = await axios.get("/api/auth/fetchUser");

      if (user.status === 200) {
        setIsLogin(true);
        setLoggedinUser(user.data);
      } else {
        setIsLogin(false);
      }
    } catch (err) {
      setIsLogin(false);
      console.error(err);
    }
  };

  const saveSettingsToAuth = async (objToSave: any, key: string) => {
    const metadata = loggedinUser?.metadata?.user_metadata ?? {};

    if (!loggedinUser?.metadata) {
      return "error";
    }

    metadata[key] = objToSave;

    try {
      const response: any = await axios.post(
        "/api/auth/saveKeys",
        {
          userID: loggedinUser?.sub,
          value: { ...metadata, key: objToSave },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("success");

      if (loggedinUser) {
        setLoggedinUser({ ...loggedinUser, metadata: response.data });
      }

      return response.data;
    } catch (error: any) {
      toast.error(
        `Error saving ${key}: ` + error.response?.data || error.message
      );

      console.error(
        "Error saving object:",
        error.response?.data || error.message
      );
      return "error";
    }
  };

  return (
    <UserContext.Provider
      value={{
        isLogin,
        loggedinUser,
        path,
        mockAPI,
        noAcc,
        setLoggedinUser,
        setNoAcc,
        setMockAPI,
        setPath,
        fetchUser,
        saveSettingsToAuth,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useMyContext must be used within a MyContextProvider");
  }
  return context;
};
