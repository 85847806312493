import "../styles/globals.scss";
import "react-toastify/dist/ReactToastify.css";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import React from "react";
import { CSSProvider } from "../context/cssContext";
import { UserProvider } from "@auth0/nextjs-auth0/client";
import { SessionProvider } from "next-auth/react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { UserAuthProvider } from "@/context/userContext";
import Head from "next/head";

const RiskMonProvider = dynamic(
  () =>
    import("../context/riskMonitorContext").then((mod) => mod.RiskMonProvider),
  { ssr: false }
);
const KBProvider = dynamic(
  () => import("@/context/knowledgebaseContext").then((mod) => mod.KBProvider),
  { ssr: false }
);

interface MyAppProps {
  Component: React.ComponentType<any>;
  pageProps: any;
}

const MyApp: React.FC<MyAppProps> = ({ Component, pageProps }) => {
  const router = useRouter();
  const isLoginPage = router.pathname === "/login";
  const isAnalyticsPage = router.pathname === "/analytics";
  const isTradingPage = router.pathname === "/trading";

  return (
    <>
      <Head>
        <title>whale.fish | Analytics</title>
        <meta
          property="og:title"
          content="Whale Fish Trading Platform"
          key="title"
        />
        <meta property="og:image" content="/images/whale-logo.webp" />
        <meta property="og:image:type" content="image/webp" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        <link rel="icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/images/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="48x48"
          href="/images/favicon-48x48.png"
        />
      </Head>
      <SessionProvider>
        <UserProvider>
          <CSSProvider>
            {isLoginPage ? (
              <Component {...pageProps} />
            ) : isAnalyticsPage || isTradingPage ? (
              <UserAuthProvider>
                <Component {...pageProps} />
              </UserAuthProvider>
            ) : (
              <UserAuthProvider>
                <RiskMonProvider>
                  <KBProvider>
                    <Component {...pageProps} />
                  </KBProvider>
                </RiskMonProvider>
              </UserAuthProvider>
            )}
          </CSSProvider>
        </UserProvider>
      </SessionProvider>
    </>
  );
};

export default MyApp;
